<!--
 * @Author: your name
 * @Date: 2021-10-27 16:56:10
 * @LastEditTime: 2021-10-27 17:29:23
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /official/src/components/phone/mobileTitle.vue
-->

<template>
  <div class="titleWrapper">
    <div>{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "MobileTitle",

  props: {
    title: {
      type: String,
      default: "aaa"
    }
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.titleWrapper {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: PingFangSC-Semibold;
  background: #fff;
}
</style>
